
    import { VueAgile } from 'vue-agile';
    export default {
        components: {
            agile: VueAgile,
        },
        data() {
            return {
                currentSlide: 0,
                options: {
                    autoplay: false,
                    navButtons: false,
                    dots: true,
                    infinite: true,
                    slidesToShow: 1,
                },
            };
        },
        computed: {
            ourWorkData() {
                return this.$store.state.ourWorkData;
            },
        },
        methods: {
            getCurrentSlide(event) {
                this.currentSlide = event.currentSlide;
            },
        },
    };
